<template>
  <div class="rule-block d-flex w-100">
    <div class="rule-operator pt-3">
      <span v-if="showGroupOperator()">{{ groupOperator }}</span>
    </div>
    <div class="align-items-center rule-box w-100">
      <div class="row">
        <div class="col-12">
          <div
            :class="[
              'p-2',
              'rule-box-cnt',
              'form-inline',
              'rounded',

              query.error ? 'rule-error' : ''
            ]"
          >
            <div class="px-1 mb-1">
              <multiselect
                v-model="filterIdObj"
                track-by="value"
                label="text"
                :options="filterOptions"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                @close="ruleIdChanged"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.text }}</span>
                </template>
              </multiselect>
              <!-- <b-form-select
                class="form-control"
                v-model="query.filterId"
                @change="ruleIdChanged"
                :options="filterOptions"
              >
              </b-form-select> -->
            </div>

            <div class="px-1 mb-1 form-inline" v-if="hasChildOperand">
              <multiselect
                v-model="query.childOperand"
                track-by="id"
                label="label"
                :options="filterChildOperands"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select value-multi-select "
                @close="setChildDependants"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.label }}</span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="d-flex">
                    <div class="option-text" :id="`at-${props.option.id}`">
                      {{ props.option.label }}
                    </div>
                  </div>
                  <b-popover
                    :target="`at-${props.option.id}`"
                    triggers="hover"
                    placement="right"
                    boundary="window"
                    v-if="props.option.description"
                  >
                    {{ props.option.description }}
                  </b-popover>
                </template>
              </multiselect>
            </div>
            <div class="px-1 mb-1" v-if="hasOperand">
              <div v-if="ruleOperators.length === 1">
                <i class="fa fa-arrow-right"></i>
              </div>
              <div v-else>
                <multiselect
                  v-model="operatorObj"
                  track-by="id"
                  label="label"
                  :options="ruleOperators"
                  :multiple="false"
                  :close-on-select="true"
                  :showLabels="false"
                  placeholder="Select one"
                  class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                  @close="setOperatorDependants"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option-text">{{ props.option.label }}</span>
                  </template>
                </multiselect>
                <!-- <select
                  class="form-control operator-select"
                  v-model="query.operator"
                  @change="setOperatorDependants"
                >
                  <option
                    v-for="(operator, index) in ruleOperators"
                    :key="index"
                    :value="operator.id"
                    >{{ operator.label }}</option
                  >
                </select> -->
              </div>
            </div>
            <div class="px-1 mb-1" v-if="query.operator && ruleForOptionType">
              <div v-if="ruleForOptionType.type === 'number'">
                <div v-if="isBetweenOperator">
                  <input
                    type="number"
                    v-model="query.value[0]"
                    class="form-control rounded"
                    pattern="[0-9.]+"
                    @blur="fetchCount()"
                    @keyup="clearResult()"
                  />
                  <input
                    type="number"
                    v-model="query.value[1]"
                    class="ml-2 form-control rounded"
                    pattern="[0-9.]+"
                    @blur="fetchCount()"
                    @keyup="clearResult()"
                  />
                </div>
                <div v-else>
                  <input
                    type="number"
                    v-model="query.value[0]"
                    class="form-control rounded"
                    pattern="[0-9.]+"
                    @blur="fetchCount()"
                    @keyup="clearResult()"
                  />
                </div>
              </div>

              <div v-else-if="ruleForOptionType.type === 'select'">
                <!-- <select
                class="form-control value-select"
                v-model="query.value[0]"
                @change="fetchResultCount()"
                v-if="showSingleSelect()"
              >
                <option
                  v-for="(option, index) in ruleOptions"
                  :key="index"
                  :value="option.id"
                  >{{ option.label }}</option
                >
              </select> -->
                <multiselect
                  v-model="query.value[0]"
                  track-by="id"
                  label="label"
                  :options="ruleOptions"
                  :multiple="false"
                  :close-on-select="true"
                  :showLabels="false"
                  placeholder="Select one"
                  class="simple-select group-select form-control rounded value-multi-select"
                  @close="fetchResultCount()"
                  v-if="isConformingLoanLimit"
                >
                  <template
                    slot="selection"
                    slot-scope="{ values }"
                    v-if="values.length"
                  >
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option-text">{{ props.option.label }}</span>
                  </template>
                </multiselect>

                <multiselect
                  v-model="query.value"
                  track-by="id"
                  label="label"
                  :options="ruleOptions"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  :placeholder="emptyValue ? 'Select one or more' : ''"
                  class="simple-select group-select form-control rounded value-multi-select advanced-query-select eo-multi-select"
                  @close="fetchResultCount()"
                  v-else
                >
                  <span
                    class="arrow"
                    slot="caret"
                    slot-scope="{ toggle }"
                    @mousedown.prevent="toggle"
                  >
                    <i class="fa fa-angle-double-down fa-lg double-arrow"></i>
                  </span>
                  <template
                    slot="selection"
                    slot-scope="{ values }"
                    v-if="values.length"
                  >
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </multiselect>
              </div>

              <div v-else>
                <input
                  type="text"
                  v-model="query.value[0]"
                  class="form-control rounded"
                  @blur="fetchCount()"
                  @keyup="clearResult()"
                />
              </div>
            </div>

            <div class="close-icon">
              <i class="fa fa-times-circle fa-lg" @click="removeFilter"></i>
            </div>
            <div class="rule-count" v-if="renderedIn === 'Query'">
              <span v-if="query.loading">
                <i class="fa fa-spinner fa-spin"></i>
              </span>
              <span v-else-if="!hideResultCount">
                {{ query.count | numberFormat }}
                <span class="d-none">{{ resultCount }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import { EventBus } from '@/plugins/events.js'
// specific utilities
import QueryTransformer from '../helpers/query.transformer.js'
import _ from 'lodash'

export default {
  name: 'RuleFilter',
  components: {
    Multiselect
  },
  props: {
    filters: {
      type: Array
    },
    query: {
      type: Object
    },
    rootQuery: {
      type: Object
    },
    groupOperator: {
      type: String
    },
    index: {
      type: Number
    },
    renderedIn: {
      type: String,
      default: 'Report'
    }
  },

  data() {
    return {
      resultCount: null,
      operatorObj: null,
      filterIdObj: null,
      basicOperators: [
        'equal',
        'less',
        'less or equal',
        'greater',
        'greater or equal',
        'between',
        'not between'
      ]
    }
  },
  mounted() {
    if (this.query.filterId) {
      this.filterIdObj = this.filterOptions.find(
        opt => opt.value == this.query.filterId
      )
    }
    if (this.query.operator) {
      this.operatorObj = this.ruleOperators.find(
        opt => opt.id == this.query.operator
      )
    }
  },
  computed: {
    ...mapGetters('AdvancedQuery', ['isMortgageLender']),
    hasChildOperand() {
      return this.chosenFilter && this.chosenFilter.children.length > 0
    },
    chosenFilter() {
      if (!this.query.filterId) {
        return null
      }

      return this.filters.find(f => f.id === this.query.filterId)
    },
    ruleForOptionType() {
      return this.hasChildOperand
        ? this.chosenChildOperandFilter
        : this.chosenFilter
    },
    chosenChildOperandFilter() {
      if (!this.query.childOperand) {
        return null
      }

      return this.chosenFilter.children.find(
        childOperand => childOperand.id === this.query.childOperand.id
      )
    },
    hasOperand() {
      return this.hasChildOperand ? this.query.childOperand : this.query.operand
    },
    ruleOperators() {
      if (!this.hasOperand) {
        return []
      }
      let operandRule = this.hasChildOperand
        ? this.chosenChildOperandFilter
        : this.chosenFilter
      if (operandRule) {
        let operators = operandRule.operators

        if (this.isNestedOperator(operators)) {
          return operators.map(operator => {
            return { id: operator.label, label: operator.label }
          })
        } else {
          return operators.map(operator => {
            return { id: operator, label: operator }
          })
        }
      } else {
        return []
      }
    },
    hideResultCount() {
      return this.query.count === null
    },
    filterOptions() {
      let opt = this.filters.map(f => {
        return { value: f.id, text: f.label }
      })
      //opt.push({ value: null, text: 'Please Select' })
      return opt
    },
    filterChildOperands() {
      if (!this.hasChildOperand) {
        return []
      }
      return this.chosenFilter.children.map(operand => {
        return {
          id: operand.id,
          label: operand.label,
          description: operand.description,
          property: operand.element_property
        }
      })
    },
    isBetweenOperator() {
      return ['between', 'not between'].includes(this.query.operator)
    },
    ruleOptions() {
      if (!this.query.operator) {
        return []
      }
      // array is temporary, needs to replaced with proper input field
      let operandRule = this.hasChildOperand
        ? this.chosenChildOperandFilter
        : this.chosenFilter
      let options = operandRule.options || []
      if (typeof options[0] === 'object') {
        return options.map(option => {
          return { id: option.value, label: option.label }
        })
      } else {
        return options.map(option => {
          return { id: option, label: option }
        })
      }
    },
    emptyValue() {
      let value = this.query.value[0]
      return Array.isArray(value)
        ? this.query.value[0].length === 0
        : [undefined, ''].includes(this.query.value[0])
    },
    isConformingLoanLimit() {
      let operandRule = this.hasChildOperand
        ? this.chosenChildOperandFilter
        : this.chosenFilter
      return (
        operandRule && operandRule.element_property === 'conforming_loan_limit'
      )
    }
  },
  methods: {
    isNestedOperator(operators) {
      return operators.length > 0 && typeof operators[0] === 'object'
    },
    showGroupOperator() {
      return this.index !== 0
    },
    canFetchCount() {
      if (this.renderedIn === 'Query') {
        if (this.isBetweenOperator) {
          return this.query.value[0] && this.query.value[1]
        } else {
          return !this.emptyValue
        }
      } else return false
    },
    fetchCount() {
      if (this.canFetchCount()) {
        this.$emit('filterUpdated', this.query)
        this.query.loading = true
        let qt = new QueryTransformer(this.rootQuery)
        let obj = {
          element: qt.transformToElement(this.query),
          criteria: qt.transformToCriteria(this.query)
        }
        this.$http
          .get('/api/advanced_query/count', {
            params: {
              query: obj,
              is_mbc: this.isMortgageLender
            },
            handleErrors: true
          })
          .then(
            res => {
              if (!_.isEmpty(res.data.errors)) {
                let error = res.data.errors['job_search']
                  ? res.data.errors['job_search']
                  : res.data.errors['site_search']
                  ? res.data.errors['site_search']
                  : res.data.errors['contact_search']
                let message = error.includes('Lexical')
                  ? 'Please make sure the reserved characters(+ - = && || > < ! ( ) { } [ ] ^ " ~ * ? : \\ /) are used appropriately.'
                  : 'Please enter a valid search criteria.'
                this.$toasted.show(message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
              //Update rule count??
              this.query.count = res.data.count
              this.query.loading = false
              // EventBus.$emit('countUpdated', {
              //   id: this.query.id,
              //   count: res.data.count
              // })
            },
            () => {
              EventBus.$emit('countUpdated', { id: this.query.id, count: null })
            }
          )
      } else if (this.renderedIn === 'Report') {
        this.$emit('filterUpdated', this.query)
      } else if (this.query.count !== null) {
        this.query.count = null
        this.resultCount = null
      }
    },
    clearResult() {
      this.$emit('clearResult')
    },
    fetchResultCount() {
      this.$emit('clearResult')
      this.fetchCount()
    },
    ruleIdChanged() {
      this.query.filterId = this.filterIdObj.value
      this.query.operand = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.value = []
      this.query.count = null

      this.$emit('clearResult')
    },
    setChildDependants() {
      this.query.operator = null
      this.query.count = null
      this.query.value = []
      if (this.chosenFilter) {
        this.query.operator = this.ruleOperators.length
          ? this.ruleOperators[0].id
          : null
      }
      this.$emit('clearResult')
    },
    setOperatorDependants() {
      this.query.operator = this.operatorObj.id
      this.query.value = []
      this.query.count = null
      this.$emit('clearResult')
    },
    removeFilter() {
      this.$emit('removeFilter', this.query.id)
    }
  },
  watch: {
    'query.filterId': {
      handler: function() {
        if (this.query.filterId) {
          this.filterIdObj = this.filterOptions.find(
            opt => opt.value == this.query.filterId
          )
        } else {
          this.filterIdObj = null
        }
      }
    },
    'query.operator': {
      handler: function() {
        if (this.query.operator) {
          this.operatorObj = this.ruleOperators.find(
            opt => opt.id == this.query.operator
          )
        } else {
          this.operatorObj = null
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.rule-operator {
  margin-right: 0.5rem;
  min-width: 2rem;
  font-weight: bold;
}
.close-icon {
  margin-left: auto;
}
.rule-count {
  margin-left: 0.5rem;
  min-width: 3.2rem;
  font-weight: bold;
  //padding-top: 1.25rem;
}
.option-text {
  font-size: 0.875rem;
}
.rule-error {
  border: solid 1px #dc3545;
  border-left: solid 1px #dc3545 !important;
  background: #fae3e3;
}
</style>
